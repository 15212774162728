import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ParkBathSupport = () => (
  <Layout>
    <SEO title="Park Bath support" />
    <h1>Park Bath Privacy policy</h1>
    <p>
      We collect no information about you at all. The server that is hit to get
      the data is provided by Bath Hacked and Bath and North East Somerset
      council.
    </p>
    <p>
      If you would like to speak more on this then please send an email to: me
      [at] tomandrews [dot] co [dot] uk
    </p>
  </Layout>
)

export default ParkBathSupport
